import { Buffer } from 'buffer';
import axios from 'axios';
import AuthStorage from './AuthStorage';
const API_ENDPOINT = 'https://trainer-api.astute.health';

export default class {

    buildAuthHeader() {
        return {
            headers: {
                Authorization: AuthStorage.getAuthToken()
            }
        }
    }

    /**
     * @param {*} authComponent (TODO: we support email but phone number support in future)
    */
    async sendAccessCode(authComponent) {
        try {
            let response = await axios.post(`${API_ENDPOINT}/sendAccessCode`, {
                email: `${authComponent}`.trim()
            });
            if (response.status !== 200) {
                return false;
            }
            return true;
        } catch (e) { }
        return false;
    }

    async verifyLoginAccessCode(email, accessCode) {
        try {
            let response = await axios.post(`${API_ENDPOINT}/verifyLoginAccessCode`, {
                email: `${email}`.trim(),
                accessCode
            });
            if (response.status !== 200) {
                return null;
            }
            return response.data;
        } catch (e) { }
        return false;
    }

    /**
     * If Token valid 200 response and we are logged in
    */
    async isAccessTokenValid() {
        try {
            let response = await axios.get(`${API_ENDPOINT}/isAccessTokenValid`, {
                ...(this.buildAuthHeader())
            });
            if (response.status !== 200) {
                return false;
            }
            return true;
        } catch (e) {
            return false;
        }
    }

    async isUserAcknowledged(email, accessCode) {
        try {
            let response = await axios.post(`${API_ENDPOINT}/isUserAcknowledged`, {
                email: `${email}`.trim(),
                accessCode
            });
            if (response.status !== 200) {
                return null;
            }
            return response.data;
        } catch (e) {
            console.log(e);
        }
        return false;
    }

    async postUserAcknowledged(email, accessCode) {
        try {
            let response = await axios.post(`${API_ENDPOINT}/postUserAcknowledged`, {
                email: `${email}`.trim(),
                accessCode
            });
            if (response.status !== 200) {
                return null;
            }
            return response.data;
        } catch (e) {
            console.log(e);
        }
        return false;
    }

    async getProfile() {
        try {
            let response = await axios.get(`${API_ENDPOINT}/getProfile`, {
                ...(this.buildAuthHeader())
            });
            if (response.status !== 200) {
                return null;
            }
            return response;
        } catch (e) {
            return null;
        }
    }

    async getProfileConfig() {
        try {
            let response = await axios.get(`${API_ENDPOINT}/getProfileConfig`, {
                ...(this.buildAuthHeader())
            });
            if (response.status !== 200) {
                return null;
            }
            return response;
        } catch (e) {
            return null;
        }
    }

    async updateProfile(action = 'demographic-update', data) {
        try {
            let response = await axios.post(`${API_ENDPOINT}/updateProfile`, {
                action: action,
                data: data
            }, {
                ...(this.buildAuthHeader())
            });
            if (response.status !== 200) {
                return null;
            }
            return response;
        } catch (e) {
            console.error(e);
            return null;
        }
    }

    async getAllTests(lastEvaluatedKey) {
        try {
            let response = await axios.get(`${API_ENDPOINT}/getAllTests`, {
                ...(this.buildAuthHeader()),
                params: {
                    lastEvaluatedKey:
                        !!lastEvaluatedKey ?
                            Buffer.from(new TextEncoder().encode(JSON.stringify(lastEvaluatedKey))).toString("base64")
                            : undefined
                }
            });
            if (response.status !== 200) {
                return null;
            }
            return response;
        } catch (e) {
            return null;
        }
    }

    async getRandomTests(numTests = 1, specialty) {
        try {
            let response = await axios.get(`${API_ENDPOINT}/getRandomTests`, {
                params: { numTests, specialty },
                ...(this.buildAuthHeader())
            });
            if (response.status !== 200) {
                return null;
            }
            return response;
        } catch (e) {
            return null;
        }
    }

    async getUserTests(lastEvaluatedKey) {
        try {
            let response = await axios.get(`${API_ENDPOINT}/getUserTests`, {
                ...(this.buildAuthHeader()),
                params: {
                    lastEvaluatedKey:
                        !!lastEvaluatedKey ?
                            Buffer.from(new TextEncoder().encode(JSON.stringify(lastEvaluatedKey))).toString("base64")
                            : undefined
                }
            });
            if (response.status !== 200) {
                return null;
            }
            return response;
        } catch (e) {
            return null;
        }
    }

    async getUserTestSessions(lastEvaluatedKey) {
        try {
            let response = await axios.get(`${API_ENDPOINT}/getUserTestSessions`, {
                ...(this.buildAuthHeader()),
                params: {
                    lastEvaluatedKey:
                        !!lastEvaluatedKey ?
                            Buffer.from(new TextEncoder().encode(JSON.stringify(lastEvaluatedKey))).toString("base64")
                            : undefined
                }
            });
            if (response.status !== 200) {
                return null;
            }
            return response;
        } catch (e) {
            return null;
        }
    }

    async getUserSessionTestResults(sessionTestIds) {
        try {
            let response = await axios.get(`${API_ENDPOINT}/getUserSessionTestResults`, {
                ...(this.buildAuthHeader()),
                params: {
                    sessionTestIds
                }
            });
            if (response.status !== 200) {
                return null;
            }
            return response;
        } catch (e) {
            return null;
        }
    }

    async submitUserSession(data) {
        try {
            let response = await axios.post(`${API_ENDPOINT}/submitUserSession`, {
                data
            }, {
                ...this.buildAuthHeader()
            });
            if (response.status !== 200) {
                return false;
            }
            return true;
        } catch (e) { }
        return false;
    }

    async saveTestFeedback(data) {
        try {
            let response = await axios.put(`${API_ENDPOINT}/saveTestFeedback`, {
                data
            }, {
                ...this.buildAuthHeader()
            });
            if (response.status !== 200) {
                return false;
            }
            return true;
        } catch (e) { }
        return false;
    }

    async getUserProfileSpecialties() {
        try {
            let response = await axios.get(`${API_ENDPOINT}/getUserProfileSpecialties`, {
                ...(this.buildAuthHeader())
            });
            if (response.status !== 200) {
                return null;
            }
            return response.data;
        } catch (e) {
            return null;
        }
    }
}