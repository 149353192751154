import React, { useState, useEffect } from "react";
import { NewUserForm, SessionStarter, MedicalCodingSession, Loading } from '../components';
import { AppApi } from "../services";

const appApi = new AppApi();

export default (props) => {
    const [isNewUser, setIsNewUser] = useState(true);
    const [sessionStarted, setSessionStarted] = useState(false);
    const [selectedNoteCount, setSelectedNoteCount] = useState(null);
    const [selectedSpecialty, setSelectedSpecialty] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchUserProfile = async () => {
            try {
                const user = await appApi.getProfile();
                setIsNewUser(!user?.data?.isRegistered);
            } catch (error) {
                console.error("Failed to fetch user profile:", error);
            } finally {
                setLoading(false);
            }
        };

        fetchUserProfile();
    }, []);

    const handleStartSession = (noteCount, specialty) => {
        setSelectedNoteCount(noteCount);
        setSelectedSpecialty(specialty);
        setSessionStarted(true);
    };

    const handleFormSubmit = (response) => {
        console.log("RESPONSE:", response)
        setIsNewUser(!response.data.isRegistered);
    };

    if (loading) {
        return (
            <Loading visible={true} />
        );
    }

    return (
        <div style={{ marginBottom: '15px' }}>
            {!!isNewUser && <NewUserForm mode="register" onSubmit={handleFormSubmit} />}
            {!isNewUser && !sessionStarted && <SessionStarter onStartSession={handleStartSession} />}
            {!isNewUser && sessionStarted && (
                <MedicalCodingSession
                    noteCount={selectedNoteCount}
                    specialty={selectedSpecialty}
                    handleFinish={() => {
                        setSessionStarted(false);
                        window.location.replace('/results');
                    }}
                    onError={() => {
                        setSelectedNoteCount(null);
                        setSelectedSpecialty(null);
                        setSessionStarted(false);
                    }}
                />
            )}
        </div>
    );
};
