import React, { useState, useEffect } from 'react';
import { AppApi, JsUtils } from "../services";
import MedicalCodingComponent from './MedicalCodingComponent';
import { Container } from 'react-bulma-components';
import { toast } from 'react-toastify';
import Loading from './Loading';

const appApi = new AppApi();

export default (props) => {
    const [isLoading, setIsLoading] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [currentNoteIndex, setCurrentNoteIndex] = useState(0);
    const [codingData, setCodingData] = useState([]);
    const [notes, setNotes] = useState([]);
    const [notesData, setNotesData] = useState([
        // fetched notes data here, each with their own icdCodes and cptCodes arrays
    ]);
    const [testTimers, setTestTimers] = useState(Array(props.noteCount).fill(0));
    const [currentTestTime, setCurrentTestTime] = useState(0);

    const getSessionTime = () => {
        return testTimers.reduce((acc, curr) => acc + curr, 0);
    }

    useEffect(() => {
        const timerId = setInterval(() => {
            setTestTimers(prevTimers => {
                const updatedTimers = [...prevTimers];
                updatedTimers[currentNoteIndex]++;
                setCurrentTestTime(updatedTimers[currentNoteIndex]);
                return updatedTimers;
            });
        }, 1000);

        return () => clearInterval(timerId);
    }, [currentNoteIndex]);

    useEffect(() => {
        setCurrentTestTime(testTimers[currentNoteIndex]);
    }, [currentNoteIndex, testTimers]);

    useEffect(() => {
        const fetchNotes = async () => {
            try {
                setIsLoading(true);
                const response = await appApi.getRandomTests(props.noteCount, props.specialty);
                if (!response?.data?.items?.length) {
                    throw new Error('No tests available');
                }
                setNotes(response.data.items);
                // Initialize notesData based on fetched notes
                setNotesData(response.data.items.map(note => ({
                    noteText: note.chartNote,
                    icdCodes: [''],
                    cptCodes: [''],
                })));
            } catch (error) {
                console.error("Failed to fetch notes:", error);
                toast.warn('There currently are no new tests for this specialty, please select another one');
                if (props.onError) {
                    props.onError();
                }
            } finally {
                setIsLoading(false);
            }
        };

        fetchNotes();
    }, [props.noteCount, props.specialty, props.onError]);

    useEffect(() => {
        const submitSession = async () => {
            if (!!isSubmitting) {
                try {
                    // Combine codingData with corresponding original note data
                    const mergedData = codingData.map((data, index) => ({
                        ...data,
                        ...notes[index]
                    }));


                    console.log("FINAL MERGED DATA:", mergedData);
                    const sessionTime = getSessionTime();
                    const sessionTests = mergedData.map((item, index) => {
                        return {
                            pk: item.pk,
                            sk: item.sk,
                            testId: item.pk,
                            diags: item.icdCodes,
                            cpts: item.cptCodes,
                            medicalRecord: item.chartNote,
                            // Use the testTimers value for the current test
                            seconds: testTimers[index]
                        }
                    });
                    console.log("FINAL SECONDS AND SESSION TESTS DATA:", sessionTime, sessionTests);
                    await appApi.submitUserSession({
                        sessionTime: sessionTime,
                        sessionTests: sessionTests
                    });
                    toast.success("Submitted Successfully!");
                    props.handleFinish();
                } catch (error) {
                    console.error('Failed to submit session:', error);
                } finally {
                    setIsSubmitting(false);
                }
            }
        }

        submitSession();
    }, [codingData, isSubmitting, notes]);

    const handleNoteSubmission = (submittedData) => {
        // console.log("Handling note submission with:", submittedData);

        // Update the codingData at the current index
        setCodingData(prevData => {
            const newData = [...prevData];
            newData[currentNoteIndex] = submittedData;
            return newData;
        });

        // Check if this is the last note
        if (currentNoteIndex >= notes.length - 1) {
            try {
                // TODO: we could start loading here too or just use this value
                setIsSubmitting(true);
            } catch (error) {
                console.error('Failed to submit session:', error);
            }
            return;
        }
        // If it's not, move to the next note
        setCurrentNoteIndex(prevIndex => prevIndex + 1);
    };

    const handleDataChange = (newCodes) => {
        setNotesData(prevData => prevData.map((data, idx) =>
            idx === currentNoteIndex ? { ...data, ...newCodes } : data
        ));
    };

    const handleBackAction = () => {
        // Navigate back, ensuring not to go below 0
        setCurrentNoteIndex(prev => Math.max(0, prev - 1));
    };

    /**
     * Commented out for now but leave and add for advanced option
     * @returns 
     */
    const renderMedicalCodingSessionInfoData = () => {
        return (
            <div className="textContainer">
                <h1 className="rightAlignedTextBoldUnderline">
                    Medical Coding Session
                </h1>
                <p className="rightAlignedText">
                    Note {currentNoteIndex + 1} of {props.noteCount}
                </p>
                <p className="rightAlignedText">
                    Total Time: {JsUtils.formatTime(getSessionTime())}
                </p>
                <p className="rightAlignedText">
                    Current Chart Time: {JsUtils.formatTime(currentTestTime)}
                </p>
            </div>
        )
    }

    if (isLoading) {
        return (
            <div style={{ marginTop: '10px' }}>
                <Loading />
            </div>
        )
    }
    return (
        <Container style={{ marginTop: '10px' }}>
            <div className="headerContainer">
                <button
                    className="backButton button is-outlined"
                    onClick={handleBackAction}
                    disabled={currentNoteIndex === 0}
                >
                    Back
                </button>
                {/* Commented out for now but leave and add for advanced option */}
                {/* {renderMedicalCodingSessionInfoData()} */}
            </div>

            {notes.length > 0 && (
                <MedicalCodingComponent
                    key={currentNoteIndex}
                    chartNoteIndex={currentNoteIndex}
                    totalChartNotes={notesData?.length}
                    chartNote={notesData[currentNoteIndex]?.noteText || ""}
                    icdCodes={notesData[currentNoteIndex]?.icdCodes || ['']}
                    cptCodes={notesData[currentNoteIndex]?.cptCodes || ['']}
                    onCodesChange={handleDataChange}
                    onSubmit={handleNoteSubmission}
                    isFinalTest={currentNoteIndex === notes.length - 1}
                />
            )}
        </Container>
    );
};
