import React, { useState, useEffect } from 'react';
import { Button, Container, Form, Columns } from 'react-bulma-components';
import { toast } from 'react-toastify';
import { AppApi } from '../services';

const appApi = new AppApi();

const ICD10_CM_REGEX = /^([a-tA-T]|[v-zV-Z])\d[a-zA-Z0-9](\.[a-zA-Z0-9]{1,4})?$/;
const CPT_REGEX = /^([0-9]{4}([A-Za-z0-9])?|[A-Za-z][0-9]{3,4})(-[A-Za-z0-9]{1,6})?(-[A-Za-z0-9]{1,6})*$/;
const MAX_CODES = {
    icdCodes: 12,
    cptCodes: 6
};

// TooltipWrapper component for showing rationale on hover
const TooltipWrapper = ({ children, tooltipText }) => {
    const [isHovered, setIsHovered] = useState(false);

    const tooltipStyle = {
        position: 'absolute',
        bottom: '100%',
        left: '50%',
        transform: 'translateX(-50%)',
        backgroundColor: '#0D98BA',
        color: 'white',
        padding: '8px 12px',
        borderRadius: '4px',
        fontSize: '14px',
        fontWeight: 500,
        display: 'inline-block',
        maxWidth: 'calc(100vw - 30px)', // Prevents overflowing the viewport
        minWidth: '400px',             // Ensures the tooltip isn't too narrow
        whiteSpace: 'normal',          // Allows natural wrapping
        overflowWrap: 'break-word',    // Breaks words only when needed
        marginBottom: '8px',
        zIndex: 1000,
        textAlign: 'center'
    };

    return (
        <div
            style={{ position: 'relative', display: 'inline-block' }}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
        >
            {children}
            {isHovered && (
                <div style={tooltipStyle}>
                    <strong style={{ color: "white" }}>Answer:</strong> {tooltipText}
                </div>
            )}
        </div>
    );
};

export default (props) => {
    const cleanedNote = props.chartNote.replace(/\n{2,}/g, '\n');
    const [codes, setCodes] = useState({
        icdCodes: props.icdCodes || [''],
        cptCodes: props.cptCodes || ['']
    });
    const [feedback, setFeedback] = useState(props?.feedback || '');
    const [showTooltip, setShowTooltip] = useState(false);
    // Default is false if not provided
    const viewMode = props.viewMode || false;

    useEffect(() => {
        setCodes({
            icdCodes: props.icdCodes || [''],
            cptCodes: props.cptCodes || ['']
        });
    }, [props.icdCodes, props.cptCodes]);

    const handleFeedbackChange = (e) => {
        setFeedback(e.target.value);
    };

    const handleStoreFeedback = async () => {
        try {
            await appApi.saveTestFeedback({
                testId: props.sessionTestId,
                feedback
            });
            toast.success("Feedback saved.");
        } catch (e) {
            toast.error("Error saving feedback.");
        }
    };

    const areCodesValid = () => {
        return codes.icdCodes.every(code => ICD10_CM_REGEX.test(code)) &&
            codes.cptCodes.every(code => code === '' || CPT_REGEX.test(code));
    };

    const addCodeField = (type) => {
        setCodes(prevCodes => {
            // Check if the current length is less than the max allowed
            if (prevCodes[type].length < MAX_CODES[type]) {
                const newCodes = { ...prevCodes };
                newCodes[type] = [...newCodes[type], ''];
                return newCodes;
            } else {
                toast.error("Max code reached.")
                return prevCodes;
            }
        });
    };

    const removeCodeField = (type, indexToRemove) => {
        setCodes(prevCodes => {
            const newCodes = { ...prevCodes };
            newCodes[type] = newCodes[type].filter((_, index) => index !== indexToRemove);
            return newCodes;
        });
    };

    const handleSubmit = () => {
        props.onSubmit(codes)
    };

    const handleCodeChangeAndNotify = (type, index, value) => {
        // Change the value to uppercase
        const upperCasedValue = value.toUpperCase();

        // Construct the new codes using the uppercased value
        const newCodes = {
            ...codes,
            [type]: codes[type].map((code, codeIndex) =>
                codeIndex === index ? upperCasedValue : code
            )
        };

        // Update local state with the new codes
        setCodes(newCodes);

        // Notify the parent component about the change
        props.onCodesChange(newCodes);
    };

    const CustomTooltip = ({ message }) => (
        <div style={{
            backgroundColor: '#0D98BA', // Orange color for warning
            color: 'white',
            padding: '8px 12px',
            borderRadius: '4px',
            position: 'absolute',
            zIndex: '1000',
            top: '100%',
            left: '50%',
            transform: 'translateX(-50%)',
            boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.2)',
            fontSize: '14px',
            fontWeight: '500',
            whiteSpace: 'nowrap',
            marginTop: '8px'
        }}>
            {message}
            <div style={{
                position: 'absolute',
                top: '0',
                left: '50%',
                marginLeft: '-5px',
                borderWidth: '5px',
                borderStyle: 'solid',
                borderColor: 'transparent transparent #0D98BA transparent', // Arrow color matches the tooltip
                transform: 'translateX(-50%) translateY(-100%)'
            }} />
        </div>
    );


    return (
        <Container>
            <Columns>
                {/* Chart Note Section */}
                <Columns.Column size={8}>
                    <Container>
                        <h1 className="title is-4">Chart Note <span className='is-primary-txt'>{props?.chartNoteIndex + 1}</span> of {props?.totalChartNotes}</h1>
                        <div className="box" style={{ overflowY: 'scroll', maxHeight: '75%', whiteSpace: 'pre-line' }}>
                            {cleanedNote}
                        </div>
                    </Container>
                </Columns.Column>

                {/* Coding Section */}
                <Columns.Column size={4}>
                    <Container>
                        {/* TODO: remove below if we aren't keeping, spaces are there before */}
                        {/* <h1 className="title is-4">Outpatient Coding</h1> */}
                        <br />
                        <br />

                        {/* Correct ICD Codes with Tooltip */}
                        {props.viewMode && props.correctIcdCodes && (
                            <>
                                <h2 style={{ marginBottom: '15px' }} className="title is-5">Correct Diagnosis Codes:</h2>
                                {props.correctIcdCodes.map((code, index) => (
                                    <div key={index} style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '5px' }}>
                                        <TooltipWrapper tooltipText={props.correctDiagAnswerDesc}>
                                            <Form.Input
                                                className="is-primary-green-correct-bg"
                                                disabled={true}
                                                style={{ flex: 1, marginRight: '5px' }}
                                                value={code}
                                                placeholder={`Correct ICD Code #${index + 1}`}
                                            />
                                        </TooltipWrapper>
                                    </div>
                                ))}
                            </>
                        )}

                        {/* ICD Codes */}
                        <h2 className="title is-5" style={{ marginTop: '20px', marginBottom: '10px' }}>Diagnosis Codes:</h2>
                        <span style={{ fontStyle: 'italic', fontSize: '0.9rem' }}>
                            Enter standard ICD-10-CM codes here. Periods when applicable. First-listed is Primary/Principal Dx.
                        </span>
                        {codes.icdCodes.map((code, index) => (
                            <div key={index} style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '5px' }}>
                                <Form.Input
                                    className={`${code && !ICD10_CM_REGEX.test(code) ? 'invalid-input' : ''}`}
                                    disabled={viewMode}
                                    style={{ flex: 1, marginRight: '5px' }}
                                    value={code}
                                    onChange={e => handleCodeChangeAndNotify('icdCodes', index, e.target.value)}
                                    placeholder={`ICD Code #${index + 1}`}
                                />
                                {!viewMode && index === codes.icdCodes.length - 1 && (
                                    <div>
                                        <Button onClick={() => addCodeField('icdCodes')} style={{ marginRight: '5px' }}>+ Add</Button>
                                        {(codes.icdCodes.length > 1) && <Button onClick={() => removeCodeField('icdCodes', index)}>- Remove</Button>}
                                    </div>
                                )}
                            </div>
                        ))}

                        {/* Correct CPT Codes with Tooltip */}
                        {props.viewMode && props.correctCptCodes && (
                            <>
                                <h2 style={{ marginTop: '20px', marginBottom: '15px' }} className="title is-5">Correct Procedure Codes:</h2>
                                {props.correctCptCodes && props.correctCptCodes.length > 0 ? (
                                    props.correctCptCodes.map((code, index) => (
                                        <div key={index} style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '5px' }}>
                                            <TooltipWrapper tooltipText={props.correctCptAnswerDesc}>
                                                <Form.Input
                                                    className="is-primary-green-correct-bg"
                                                    disabled={true}
                                                    style={{ flex: 1, marginRight: '5px' }}
                                                    value={code}
                                                    placeholder={`Correct CPT Code #${index + 1}`}
                                                />
                                            </TooltipWrapper>
                                        </div>
                                    ))
                                ) : (
                                    <div className="content is-italic">
                                        <p>No CPT codes</p>
                                    </div>
                                )}
                            </>
                        )}

                        {/* CPT Codes */}
                        <h2 className="title is-5" style={{ marginTop: '20px', marginBottom: '10px' }}>Procedure Codes:</h2>
                        <span style={{ fontStyle: 'italic', fontSize: '0.9rem' }}>
                            Enter standard CPT, HCPCS or ICD-10-PCS codes. Add Modifiers as needed (i.e. 99213-25).
                        </span>
                        {codes.cptCodes.map((code, index) => (
                            <div key={index} style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '5px' }}>
                                <Form.Input
                                    className={`${code && !CPT_REGEX.test(code) ? 'invalid-input' : ''}`}
                                    disabled={viewMode}
                                    style={{ flex: 1, marginRight: '5px' }}
                                    value={code}
                                    onChange={e => handleCodeChangeAndNotify('cptCodes', index, e.target.value)}
                                    placeholder={`CPT Code #${index + 1}`}
                                />
                                {!viewMode && index === codes.cptCodes.length - 1 && (
                                    <div>
                                        <Button onClick={() => addCodeField('cptCodes')} style={{ marginRight: '5px' }}>+ Add</Button>
                                        {(codes.cptCodes.length > 1) && <Button onClick={() => removeCodeField('cptCodes', index)}>- Remove</Button>}
                                    </div>
                                )}
                            </div>
                        ))}

                        {/* Add feedback textbox and "Store Feedback" button only in view mode */}
                        {viewMode && (
                            <Container style={{ marginTop: '20px' }}>
                                <h2 className="title is-5" style={{ marginTop: '10px' }}>Chart Feedback:</h2>
                                <Form.Textarea
                                    // restrict input to 4000 characters
                                    maxLength={4000}
                                    placeholder="Provide rationale if you feel items were marked incorrect."
                                    value={feedback}
                                    onChange={handleFeedbackChange}
                                />
                                <Button
                                    style={{ marginTop: '10px' }}
                                    onClick={handleStoreFeedback}>
                                    Save Feedback
                                </Button>
                            </Container>
                        )}

                        {/* Submit Button - Only show if not in view mode */}
                        {!viewMode && (
                            <div style={{ display: 'flex', justifyContent: 'center', marginTop: '15px' }}>
                                <div
                                    onMouseEnter={() => !areCodesValid() && setShowTooltip(true)}
                                    onMouseLeave={() => setShowTooltip(false)}
                                >
                                    <Button
                                        onClick={handleSubmit}
                                        color="primary"
                                        disabled={!areCodesValid()}
                                    >
                                        {props.isFinalTest ? "Submit" : "Next"}
                                    </Button>
                                    {showTooltip && <CustomTooltip message="Codes must be entered correctly before we can continue. (Note: remove any empty entries)" />}
                                </div>
                            </div>
                        )}
                    </Container>
                </Columns.Column>
            </Columns>
        </Container>
    );
};