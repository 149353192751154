import React, { useState, useEffect } from "react";
import { Form, Button, Columns } from 'react-bulma-components';
import Select, { components } from 'react-select';
import CreatableSelect from 'react-select/creatable';
import { toast } from "react-toastify";
import { states } from './data';
import { AppApi } from "../services";

const appApi = new AppApi();

// Convert existing options to react-select format
const employerTypeOptions = [
    { value: 'hospital', label: 'Hospital' },
    { value: 'clinic', label: 'Clinic' },
    { value: 'insuranceCompany', label: 'Insurance Company' },
    { value: 'other', label: 'Other' }
];

const genderOptions = [
    { value: 'female', label: 'Female' },
    { value: 'male', label: 'Male' },
    { value: 'other', label: 'Other' },
    { value: 'preferNotToSay', label: 'Prefer not to say' }
];

const stateOptions = states.map(state => ({
    value: state.abbreviation,
    label: state.name
}));

const codingExpertiseOptions = [
    { value: 'Profee Coding', label: 'Profee Coding' },
    { value: 'Facility Coding', label: 'Facility Coding' },
    { value: 'HCC Coding', label: 'HCC Coding (Risk Adjustment)' },
    { value: 'CDI', label: 'CDI' },
    { value: 'Auditing', label: 'Auditing' }
];

const educationOptions = [
    { value: 'someHighSchool', label: 'Some High School' },
    { value: 'highSchoolGraduate', label: 'High School Graduate' },
    { value: 'someCollege', label: 'Some College' },
    { value: 'associatesDegree', label: 'Associates Degree' },
    { value: 'bachelorsDegree', label: 'Bachelors Degree' },
    { value: 'mastersDegree', label: 'Masters Degree' },
    { value: 'doctorate', label: 'Doctorate' }
];

// TODO: remove when ready (we get this dynamically now)
const codingSpecialtiesOptions = [
    { value: 'Evaluation & Management (E&M)', label: 'Evaluation & Management (E&M)' },
    { value: 'Family Practice/Internal Medicine', label: 'Family Practice/Internal Medicine' },
    { value: 'Ambulatory Surgery Center', label: 'Ambulatory Surgery Center' },
    { value: 'Cardiology', label: 'Cardiology' },
    { value: 'ER', label: 'ER' },
    { value: 'General Surgery', label: 'General Surgery' },
    { value: 'Cardiovascular and Thoracic Surgery', label: 'Cardiovascular and Thoracic Surgery' },
    { value: 'Anesthesiology', label: 'Anesthesiology' },
    { value: 'Ophthalmology', label: 'Ophthalmology' },
    { value: 'Orthopedic Medical', label: 'Orthopedic Medical' },
    { value: 'Orthopedic Surgery', label: 'Orthopedic Surgery' },
    { value: 'Gastroenterology', label: 'Gastroenterology' },
    { value: 'Dermatology', label: 'Dermatology' },
    { value: 'Neurology', label: 'Neurology' },
    { value: 'Wound Care', label: 'Wound Care' },
    { value: 'Pathology', label: 'Pathology' },
    { value: 'Palliative Care', label: 'Palliative Care' },
    { value: 'Physical Therapy', label: 'Physical Therapy' },
    { value: 'Burn Coding', label: 'Burn Coding' },
    { value: 'Radiation Oncology', label: 'Radiation Oncology' },
    { value: 'Hematology/Oncology', label: 'Hematology/Oncology' },
    { value: 'Infectious Disease', label: 'Infectious Disease' }
];

// TODO: remove when ready
const codingToolOptions = [
    { value: 'codingBooks', label: 'Coding Books (Physical)' },
    { value: 'googleSearch', label: 'Google/Search Engine' },
    { value: 'freeCodingWebsites', label: 'Free Coding Websites' },
    { value: 'optumEncoderPro', label: 'Optum EncoderPro' },
    { value: 'decisionHealthSelectCoder', label: 'DecisionHealth SelectCoder' },
    { value: 'innoviHealthFindACode', label: 'innoviHealth Find-A-Code' },
    { value: 'aapcCodify', label: 'AAPC Codify' },
    { value: 'cacAiTools', label: 'Computer-Assisted Coding (CAC) or AI Tools' },
];

export default ({ initialData = {}, mode = "register", onSubmit }) => {
    const defaultData = {
        experience: '',
        educationLevel: '',
        state: '',
        zipcode: '',
        firstName: '',
        lastName: '',
        gender: '',
        certifications: [],
        codingExpertise: [],
        codingSpecialties: [],
        employerType: '',
        otherEmployerType: '',
        codingTools: {
            codingBooks: false,
            googleSearch: false,
            freeCodingWebsites: false,
            freeCodingSite: '',
            encoders: false,
            optumEncoderPro: false,
            decisionHealthSelectCoder: false,
            innoviHealthFindACode: false,
            aapcCodify: false,
            encoderOther: '',
            cacAiTools: false,
            cacAiToolsVendor: '',
        }
    };
    const [isLoading, setIsLoading] = useState(false);
    const [formData, setFormData] = useState({ ...defaultData, ...initialData });
    const [showOtherEmployerTypeInput, setShowOtherEmployerTypeInput] = useState(false);
    const [codingSpecialtiesOptions, setCodingSpecialtiesOptions] = useState([]);

    useEffect(() => {
        const fetchSpecialties = async () => {
            const specialties = await appApi.getUserProfileSpecialties();
            if (specialties) {
                // Convert the array of strings to the format needed for react-select
                const options = specialties.map(specialty => ({
                    value: specialty,
                    label: specialty
                }));
                setCodingSpecialtiesOptions(options);
            }
        };
        
        fetchSpecialties();
    }, []); // Empty dependency array means this runs once on component mount

    useEffect(() => {
        if (formData.employerType === 'other') {
            setShowOtherEmployerTypeInput(true);
        }
    }, [formData.employerType]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleSelectChange = (selected, { name }) => {
        const value = selected ? selected.value : '';
        setFormData({
            ...formData,
            [name]: value
        });

        if (name === 'employerType') {
            setShowOtherEmployerTypeInput(value === 'other');
        }
    };

    const handleMultiSelectChange = (selected, { name }) => {
        setFormData({
            ...formData,
            [name]: selected ? selected.map(s => s.value) : []
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const zipCodeRegex = /^[0-9]{5}(?:-[0-9]{4})?$/;
        if (!zipCodeRegex.test(formData.zipcode)) {
            toast.error("Invalid Zip Code.")
            return;
        }

        if (!formData.state) {
            toast.error("Please select a state.");
            return;
        }

        setIsLoading(true);
        const response = await appApi.updateProfile(undefined, formData);
        if (response) {
            onSubmit(response);
        } else {
            toast.error("Error saving information, please try again.");
            setIsLoading(false);
            return;
        }
        setIsLoading(false);
    }

    const DropdownIndicator = () => null;

    return (
        <Columns className="is-centered">
            <Columns.Column className="is-6">
                <div className="box">
                    {mode === "register" && (
                        <>
                            <h1 className="title is-4 has-text-centered">Your Trainer® Profile</h1>
                            <p className="has-text-centered">Get started by completing your profile below.</p>
                        </>
                    )}
                    <form onSubmit={handleSubmit}>
                        <Form.Field>
                            <Form.Label>First Name</Form.Label>
                            <Form.Control>
                                <Form.Input
                                    type="text"
                                    name="firstName"
                                    value={formData.firstName}
                                    onChange={handleChange}
                                />
                            </Form.Control>
                        </Form.Field>
                        <Form.Field>
                            <Form.Label>Last Name</Form.Label>
                            <Form.Control>
                                <Form.Input
                                    type="text"
                                    name="lastName"
                                    value={formData.lastName}
                                    onChange={handleChange}
                                />
                            </Form.Control>
                        </Form.Field>
                        <Form.Field>
                            <Form.Label>Highest Level of Education</Form.Label>
                            <Form.Control>
                                <Select
                                    name="educationLevel"
                                    options={educationOptions}
                                    value={educationOptions.find(option => option.value === formData.educationLevel)}
                                    onChange={(selected) => handleSelectChange(selected, { name: 'educationLevel' })}
                                    isClearable={false}
                                    placeholder="Select education level"
                                />
                            </Form.Control>
                        </Form.Field>
                        <Form.Field>
                            <Form.Label>Years of Coding Experience</Form.Label>
                            <Form.Control>
                                <Form.Input
                                    type="number"
                                    name="experience"
                                    value={formData.experience}
                                    onChange={handleChange}
                                />
                            </Form.Control>
                        </Form.Field>
                        {/* <Form.Field>
                            <Form.Label>Gender</Form.Label>
                            <Form.Control>
                                <Select
                                    name="gender"
                                    options={genderOptions}
                                    value={genderOptions.find(option => option.value === formData.gender)}
                                    onChange={(selected) => handleSelectChange(selected, { name: 'gender' })}
                                    isClearable={false}
                                    placeholder="Select your gender"
                                />
                            </Form.Control>
                        </Form.Field> */}
                        <Form.Field>
                            <Form.Label>Coding Certifications</Form.Label>
                            <CreatableSelect
                                isMulti
                                name="certifications"
                                placeholder="Enter certifications..."
                                value={formData.certifications.map(val => ({ value: val, label: val }))}
                                onChange={(selected) => handleMultiSelectChange(selected, { name: 'certifications' })}
                                isClearable={false}
                                components={{ DropdownIndicator }}
                                onMenuOpen={() => false}
                            />
                        </Form.Field>
                        <Form.Field>
                            <Form.Label>Coding Expertise</Form.Label>
                            <Select
                                isMulti
                                name="codingExpertise"
                                options={codingExpertiseOptions}
                                className="basic-multi-select"
                                classNamePrefix="select"
                                placeholder={"Select all that apply..."}
                                value={formData.codingExpertise.map(val => ({ value: val, label: val }))}
                                onChange={(selected) => handleMultiSelectChange(selected, { name: 'codingExpertise' })}
                            />
                        </Form.Field>
                        <Form.Field>
                            <Form.Label>Coding Specialties</Form.Label>
                            <Select
                                isMulti
                                name="codingSpecialties"
                                options={codingSpecialtiesOptions}
                                className="basic-multi-select"
                                classNamePrefix="select"
                                placeholder={"Select all that apply..."}
                                value={formData.codingSpecialties.map(val => ({ value: val, label: val }))}
                                onChange={(selected) => handleMultiSelectChange(selected, { name: 'codingSpecialties' })}
                            />
                        </Form.Field>
                        <Form.Field>
                            <Form.Label>Employer Type</Form.Label>
                            <Form.Control>
                                <Select
                                    name="employerType"
                                    options={employerTypeOptions}
                                    value={employerTypeOptions.find(option => option.value === formData.employerType)}
                                    onChange={(selected) => handleSelectChange(selected, { name: 'employerType' })}
                                    isClearable={false}
                                    placeholder="Select employer type"
                                />
                            </Form.Control>
                        </Form.Field>
                        {showOtherEmployerTypeInput && (
                            <Form.Field>
                                <Form.Label>Please specify</Form.Label>
                                <Form.Control>
                                    <Form.Input
                                        type="text"
                                        name="otherEmployerType"
                                        value={formData.otherEmployerType}
                                        onChange={handleChange}
                                        placeholder="Enter other employer type"
                                    />
                                </Form.Control>
                            </Form.Field>
                        )}
                        <Form.Field>
                            <Form.Label>State</Form.Label>
                            <Form.Control>
                                <Select
                                    name="state"
                                    options={stateOptions}
                                    value={stateOptions.find(option => option.value === formData.state)}
                                    onChange={(selected) => handleSelectChange(selected, { name: 'state' })}
                                    isClearable={false}
                                    placeholder="Select your state"
                                />
                            </Form.Control>
                        </Form.Field>
                        <Form.Field>
                            <Form.Label>Zipcode</Form.Label>
                            <Form.Control>
                                <Form.Input
                                    type="text"
                                    name="zipcode"
                                    value={formData.zipcode}
                                    onChange={handleChange}
                                />
                            </Form.Control>
                        </Form.Field>
                        <Form.Field>
                            <Form.Label>What tool(s) will you be using during this coding session? (select all that apply)</Form.Label>
                            <div className="ml-4">
                                <div className="mb-2">
                                    <label className="checkbox">
                                        <input
                                            type="checkbox"
                                            checked={formData.codingTools.codingBooks}
                                            onChange={(e) => setFormData({
                                                ...formData,
                                                codingTools: {
                                                    ...formData.codingTools,
                                                    codingBooks: e.target.checked
                                                }
                                            })}
                                        />
                                        <span className="ml-2">Coding Books (Physical)</span>
                                    </label>
                                </div>

                                <div className="mb-2">
                                    <label className="checkbox">
                                        <input
                                            type="checkbox"
                                            checked={formData.codingTools.googleSearch}
                                            onChange={(e) => setFormData({
                                                ...formData,
                                                codingTools: {
                                                    ...formData.codingTools,
                                                    googleSearch: e.target.checked
                                                }
                                            })}
                                        />
                                        <span className="ml-2">Google/Search Engine</span>
                                    </label>
                                </div>

                                <div className="mb-2">
                                    <label className="checkbox">
                                        <input
                                            type="checkbox"
                                            checked={formData.codingTools.freeCodingWebsites}
                                            onChange={(e) => setFormData({
                                                ...formData,
                                                codingTools: {
                                                    ...formData.codingTools,
                                                    freeCodingWebsites: e.target.checked
                                                }
                                            })}
                                        />
                                        <span className="ml-2">Free Coding Websites</span>
                                    </label>
                                    {formData.codingTools.freeCodingWebsites && (
                                        <div className="ml-4 mt-1">
                                            <Form.Input
                                                type="text"
                                                placeholder="Specify Site"
                                                value={formData.codingTools.freeCodingSite}
                                                onChange={(e) => setFormData({
                                                    ...formData,
                                                    codingTools: {
                                                        ...formData.codingTools,
                                                        freeCodingSite: e.target.value
                                                    }
                                                })}
                                            />
                                        </div>
                                    )}
                                </div>

                                <div className="mb-2">
                                    <label className="checkbox">
                                        <input
                                            type="checkbox"
                                            checked={formData.codingTools.encoders}
                                            onChange={(e) => setFormData({
                                                ...formData,
                                                codingTools: {
                                                    ...formData.codingTools,
                                                    encoders: e.target.checked
                                                }
                                            })}
                                        />
                                        <span className="ml-2">Encoders</span>
                                    </label>
                                    {formData.codingTools.encoders && (
                                        <div className="ml-4">
                                            <div className="mb-1">
                                                <label className="checkbox">
                                                    <input
                                                        type="checkbox"
                                                        checked={formData.codingTools.optumEncoderPro}
                                                        onChange={(e) => setFormData({
                                                            ...formData,
                                                            codingTools: {
                                                                ...formData.codingTools,
                                                                optumEncoderPro: e.target.checked
                                                            }
                                                        })}
                                                    />
                                                    <span className="ml-2">Optum EncoderPro</span>
                                                </label>
                                            </div>
                                            <div className="mb-1">
                                                <label className="checkbox">
                                                    <input
                                                        type="checkbox"
                                                        checked={formData.codingTools.decisionHealthSelectCoder}
                                                        onChange={(e) => setFormData({
                                                            ...formData,
                                                            codingTools: {
                                                                ...formData.codingTools,
                                                                decisionHealthSelectCoder: e.target.checked
                                                            }
                                                        })}
                                                    />
                                                    <span className="ml-2">DecisionHealth SelectCoder</span>
                                                </label>
                                            </div>
                                            <div className="mb-1">
                                                <label className="checkbox">
                                                    <input
                                                        type="checkbox"
                                                        checked={formData.codingTools.innoviHealthFindACode}
                                                        onChange={(e) => setFormData({
                                                            ...formData,
                                                            codingTools: {
                                                                ...formData.codingTools,
                                                                innoviHealthFindACode: e.target.checked
                                                            }
                                                        })}
                                                    />
                                                    <span className="ml-2">innoviHealth Find-A-Code</span>
                                                </label>
                                            </div>
                                            <div className="mb-1">
                                                <label className="checkbox">
                                                    <input
                                                        type="checkbox"
                                                        checked={formData.codingTools.aapcCodify}
                                                        onChange={(e) => setFormData({
                                                            ...formData,
                                                            codingTools: {
                                                                ...formData.codingTools,
                                                                aapcCodify: e.target.checked
                                                            }
                                                        })}
                                                    />
                                                    <span className="ml-2">AAPC Codify</span>
                                                </label>
                                            </div>
                                            <div className="mt-1">
                                                <Form.Input
                                                    type="text"
                                                    placeholder="Specify Vendor"
                                                    value={formData.codingTools.encoderOther}
                                                    onChange={(e) => setFormData({
                                                        ...formData,
                                                        codingTools: {
                                                            ...formData.codingTools,
                                                            encoderOther: e.target.value
                                                        }
                                                    })}
                                                />
                                            </div>
                                        </div>
                                    )}
                                </div>

                                <div className="mb-2">
                                    <label className="checkbox">
                                        <input
                                            type="checkbox"
                                            checked={formData.codingTools.cacAiTools}
                                            onChange={(e) => setFormData({
                                                ...formData,
                                                codingTools: {
                                                    ...formData.codingTools,
                                                    cacAiTools: e.target.checked
                                                }
                                            })}
                                        />
                                        <span className="ml-2">Computer-Assisted Coding (CAC) or AI Tools</span>
                                    </label>
                                    {formData.codingTools.cacAiTools && (
                                        <div className="ml-4 mt-1">
                                            <Form.Input
                                                type="text"
                                                placeholder="Specify Vendor"
                                                value={formData.codingTools.cacAiToolsVendor}
                                                onChange={(e) => setFormData({
                                                    ...formData,
                                                    codingTools: {
                                                        ...formData.codingTools,
                                                        cacAiToolsVendor: e.target.value
                                                    }
                                                })}
                                            />
                                        </div>
                                    )}
                                </div>
                            </div>
                        </Form.Field>
                        <Button fullwidth color="primary" type="submit" loading={isLoading}>Save</Button>
                    </form>
                </div>
            </Columns.Column>
        </Columns >
    );
};