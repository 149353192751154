import React, { useState, useEffect } from 'react';
import { Button, Container } from 'react-bulma-components';
import Select from 'react-select';
import Modals from './Modals';
import { AppApi } from "../services";

const appApi = new AppApi();

export default (props) => {
    const [selectedNoteCount, setSelectedNoteCount] = useState(10);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedSpecialty, setSelectedSpecialty] = useState(null);
    const [specialtyOptions, setSpecialtyOptions] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const fetchProfile = async () => {
            const response = await appApi.getProfile();
            if (response?.data?.codingSpecialties) {
                const options = response.data.codingSpecialties.map(specialty => ({
                    value: specialty,
                    label: specialty
                }));
                setSpecialtyOptions(options);
                setIsLoading(false);
            }
        };

        fetchProfile();
    }, []);

    const startSession = () => {
        if (!selectedSpecialty) {
            return;
        }
        props.onStartSession(selectedNoteCount, selectedSpecialty.value);
    };

    const openModal = () => {
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    const renderDirectionalContent = () => {
        return (
            <div className="content">
                <ol>
                    <li>
                        <strong>Diagnosis Code Identification:</strong> Test your knowledge of proper medical coding with five sample charts.
                    </li>
                    <li>
                        <strong>ICD-10-CM Guidelines:</strong> Apply all appropriate coding conventions as specified by the 2023 ICD-10-CM Guidelines for correct Diagnosis Code(s).
                    </li>
                    <li>
                        <strong>Procedure Codes:</strong> Identify correct Procedure Code(s), including CPT, Evaluation & Management (E/M), and HCPCS Level II codes.
                    </li>
                    <li>
                        <strong>Modifiers:</strong> Apply modifier(s) to each Procedure Code as appropriate, based on CMS and AHA coding conventions.
                    </li>
                    <li>
                        <strong>Data Entry:</strong> You must use correct coding conventions, including a period where applicable for diagnosis codes.
                    </li>
                </ol>
            </div>
        )
    };

    return (
        <Container>
            <div className="py-6">
                {/* Header Section with original spacing */}
                <div className="has-text-centered">
                    <h1 className="title is-3" style={{ marginBottom: '40px', letterSpacing: '-0.5px' }}>
                        Ready to Train?
                    </h1>
                    <p className="subtitle is-5 has-text-grey" style={{ marginBottom: '2.5rem' }}>
                        We've prepared <span className="has-text-primary has-text-weight-semibold">10 Charts</span> based on your profile.
                    </p>
                </div>

                {/* Main Card */}
                <div>
                    <div className="is-flex is-flex-direction-column is-align-items-center">
                        {/* Specialty Selector */}
                        <div style={{ width: '100%', maxWidth: '16rem', marginBottom: '1.5rem' }}>
                            <Select
                                isLoading={isLoading}
                                options={specialtyOptions}
                                value={selectedSpecialty}
                                onChange={setSelectedSpecialty}
                                placeholder="Select Specialty..."
                                isDisabled={isLoading || specialtyOptions.length === 0}
                            />
                        </div>

                        {/* Start Session Button with CSS Tooltip */}
                        <div className="tooltip-container" style={{ marginBottom: '1.5rem' }}>
                            <Button
                                onClick={startSession}
                                color="primary"
                                size="medium"
                                fullwidth
                                disabled={!selectedSpecialty}
                                style={{
                                    height: '2.75rem',
                                    borderRadius: '0.5rem',
                                    fontWeight: '500'
                                }}
                            >
                                Start Session
                            </Button>
                            {!selectedSpecialty && (
                                <div className="tooltip">
                                    You must select a specialty to continue.
                                </div>
                            )}
                        </div>

                        {/* About The Trainer Button */}
                        <div style={{ width: '100%', maxWidth: '16rem' }}>
                            <Button
                                onClick={openModal}
                                outlined
                                color="primary"
                                fullwidth
                                style={{
                                    height: '2.75rem',
                                    borderRadius: '0.5rem',
                                    fontWeight: '500',
                                    display: 'inline-flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    gap: '0.5rem'
                                }}
                            >
                                <span className="icon is-small">
                                    <i className="fas fa-info-circle"></i>
                                </span>
                                <span>About The Trainer</span>
                            </Button>
                        </div>
                    </div>
                </div>
            </div>

            <Modals.AboutTrainerModal show={isModalOpen} onClose={closeModal} />
        </Container>
    );
};

/* <Form.Select
    onChange={(e) => setSelectedNoteCount(Number(e.target.value))}
    value={selectedNoteCount}
    style={{ marginRight: '10px' }}
    disabled={false}
>
    {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((count) => (
        <option key={count} value={count}>{count}</option>
    ))}
</Form.Select> */